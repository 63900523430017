/*
Inicio estilos layout
*/
body {
    font-size: .875rem;
  }
  
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }
  
  .sidebar .nav-link.active {
    color: #007bff;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  .p-sidebar-right .sidebar-empresas{
    width: 40rem;
  }
  
  /*
   * Navbar
   */
  
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  .user-img img {
    border-radius: 50%;
    width: 30px;
  }
  .user-img {
    display: inline-block;
    position: relative;
  }
  .status.online {
      background-color: #55ce63;
  }
  .user-img .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    margin: 0;
    position: absolute;
    right: 0;
    width: 10px;
  }
  .status {
      background-color: #263238;
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      width: 10px;
  }
  .user-menu.nav>li>a>i {
    font-size: 18px;
    line-height: 60px;
  }
  
/*
Fin estilos layout
*/
.form-label{
    font-weight: 450;
    margin-bottom: 1px;
}

.p-fieldset .p-fieldset-legend{
    padding: 10px;
}

/* DataTableDemo.css */

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button{
    font-size: 12px;
}

.ui-sidebar-lg{
    width: 700px;
}


/* TabViewDemo.css */

.tabview-demo .tabview-custom i, .tabview-demo .tabview-custom span {
    vertical-align: middle;
}

.tabview-demo .tabview-custom span {
    margin: 0 .5rem;
}

.tabview-demo .p-button {
    margin-right: .25rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.toolbaredicion{
    padding: 5px;
    position: sticky;
    position: -webkit-sticky; 
    z-index: 10000; 
    top: 0; 
}

.p-sidebar .p-sidebar-header{
    padding: 10px;
}

.p-component{
    font-size: 14px;
    line-height: 16px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item{
    padding: 5px;
}

.p-orderlist .p-orderlist-list{
    padding: 0px;
}

.toolbaredicionmateria{
    z-index: 1000;
}

/*
* DataTabe
*/
.p-datatable .p-datatable-thead > tr > th{
    padding: 10px;
}
.p-datatable .p-datatable-tbody > tr > td{
    padding: 0px 10px;
}

.inputArchivo .p-card-content{
  padding: 0;
}

.inputArchivo .p-toolbar{
  padding: 5px;
}

@media (min-width: 1200px){
    legend {
        font-size: 15px;
    }
}